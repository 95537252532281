.availability{
  display: flex;
  filter: blur(4px);
}

.availability .background{
  background-image: url("../../assets/calendar.png");
  background-color: #ababab;
  background-repeat: none;
  background-position: left top;
  background-size: cover;
  overflow: hidden;
  width: calc(100vw - 274px);
  height: 100vh;
}

.ReactModal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000040;
}

.availabilityModal,
.noAvailModal {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  top: 8%;
  right: 10%;
  bottom: 8%;
  left: 10%;
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 20px;
  outline: none;
  padding: 160px 20px 30px 20px;
}

.availabilityModal{
  padding: 0;
  flex-direction: column;
  justify-content: space-between;
}

/* modal content */
.availabilityModalInfo{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 315px;
  padding: 20px 10px 20px 30px;
  background-color: #677AA71A;
  flex: 0 1 auto;
  height: 100%;
}

.availabilityModalInfo h1 {
  font-size: 2.25rem;
  padding-top: 40px;
  padding-bottom: 15px;
}

/* meeting name */
.availabilityModalInfo h2 {
  padding-bottom: 30px;
}

.availabilityModalInfo p {
  font-size: 1rem;
  padding: 8px 0;
}

/* span in text */
.availabilityModalInfo .text {
  color: #1657F3;
}

/* bold text */
.availabilityModalInfo .bold {
  font-weight: 600;
}


/* form styles */
.availabilityModal form{
  position: relative;
  display: flex;
  /* flex-direction: column; */
  /* padding: 2%; */
}

/* input container */
.userNameInput{
  display: flex;
  flex-direction: column;
  width: 315px;
  background-color: #677AA71A;
  padding: 20px 15px 20px 25px;
}

.availabilityModal input {
  width: 315px;
  height: 48px;
  background: #FFFFFF;
  border: 1px solid #C8C8C8;
  box-sizing: border-box;
  border-radius: 6px;
}


/* submit availability button */
.availabilitySubmitBtn {
  align-self: flex-start;
  width: 150px;
  padding: 10px;
  margin-top: 20px;
  position: relative;
  right: -70px;
  margin-top: 30px;
}



/* error messages styling */
.availabilityModal .error {
  border: 1px solid #ff0000;
}

.availabilityModal .errorMessage {
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
  color: #000000;
  font-size: .8rem;
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  height: 35px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0;
  width: 315px;
}


.availabilityModal .errorMessage::before {
  display: flex;
  justify-content: center;
  align-items: center;
  content: "   ⚠   ";
  color: #ff0000;
  font-weight: 600;
  font-size: 1rem;
  border-right: 10px solid white;
  border-radius: 5px;
  white-space: pre;
  background-color: #FFDCE4;
  width: 35px;
  height: 35px;
}


/* calendar header */
.calendarContainer{
    position: relative;
    margin-left: 2%;
    margin-top: -345px;
    margin-bottom: 15px;
}
.availabilityModal .calendarHeader {
  text-align: left;
  padding-left: 0;
}

.calendarHeader {
  text-align: center;
}

.calendarHeader p{
  font-weight: 300;
  font-size: 1.3rem;
  padding-top: 10px;
  padding-bottom: 5px;
}


/* calendar styles */
.calendar_default_main {
  font-family: 'Lexend', sans-serif !important;
  font-size: 12px !important;
  border-radius: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  padding-bottom: 10px;
}

.calendarContainer .calendar_default_main {
  border: 1px solid #1657F3 !important;
}

.calendar_default_alldayheader_inner,
.calendar_default_corner_inner,
.calendar_default_cornerright_inner,
.calendar_default_colheader,
.calendar_default_rowheader_inner  {
  background-color: #C4C4C41A !important;
}

.calendarContainer .calendar_default_cell_inner {
  border-left: 1px solid grey !important;
}

.calendar_default_rowheader_inner,
.calendar_default_corner_inner {
  border-bottom: none !important;
  font-size: 16px !important;
}

.calendar_default_cell_inner {
  background-color: #FFFFFF !important;
  border-left: 1px solid grey !important;
  border-radius: 3px solid rgb(155, 155, 155) !important;
}

.calendar_default_colheader_inner,
.calendar_default_colheader,
.calendar_default_corner_inner,
.calendar_default_corner{
  font-weight: 400;
  font-size: .9rem;
}

.calendarContainer .calendar_default_colheader_inner,
.calendarContainer .calendar_default_colheader,
.calendarContainer .calendar_default_corner_inner,
.calendarContainer .calendar_default_corner{
  min-height: 50px;
}

.calendarContainer .calendar_default_colheader_inner{
  width: 30px;
  margin: 0 auto;
  border: none;
  background: transparent !important;
}

.calendarContainer .calendar_default_colheader{
  border-left: 1px solid grey !important;
}


.calendarContainer .calendar_default_main {
  margin-top: 0;
}


/* events */
.calendarContainer .calendar_default_event_inner {
  background: #1657F340 !important;
  border: 2px solid #FFFFFF !important;
  box-sizing: border-box !important;
  border-radius: 12px;
  padding: 4px 13px !important;
  font-size: .7rem !important;
  margin-left: 5px;
}


/* message when device is a phone in portrait mode */
.deviceMessage{
  background-color: #FFDCE4;
  padding: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1rem;
}



/* modal for when user enters no availability */
.noAvailModal{
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 70px 50px 50px 50px;
  text-align: center;
}

.noAvailModal .buttons{
  display: flex;
  justify-content: center;
  align-items: center;
}

.noAvailModal button{
  margin: 0 30px;
  font-size: 1rem;
}

/* media queries */
/* ipad */
@media only screen and (max-width: 1080px) {
  .availabilityModal,
  .noAvailModal{
    top: 5%;
    right: 6%;
    bottom: 5%;
    left: 6%;
  }
  .availabilityModalInfo{
    max-width: 250px;
  }

  .availabilityModal input{
    width: 250px;
  }

  .availabilitySubmitBtn{
    right: -40px;
  }

  .calendarContainer{
    margin-top: -400px;
  }
}

/* Nexus 10 Android */
@media only screen and (max-width: 800px) and (max-height: 1280px) {
  .availabilityModalInfo {
    max-width: 100%;
  }
}



/* mobile phones portrait*/
@media only screen and (max-width: 428px) and (max-height: 926px) {
  .availabilityModal,
  .noAvailModal{
    top: 1%;
    right: 1%;
    bottom: 1%;
    left: 1%;
    justify-content: flex-start;
  }

  .availabilityModalInfo{
    max-width: 100%;
    height: 300px;
  }

  .userNameInput{
    width: 365px;
  }

  .availabilityModal form {
    flex-direction: column;
  }

  .availabilityModal input{
    width: 350px;
  }

  .calendarContainer {
    height: 0;
    width: 0;
    opacity: 0;
  }

  /* no availability modal */
  .noAvailModal{
    justify-content: space-evenly;
    padding: 0;
  }
  .noAvailModal h1{
    font-size: 1.8rem;
    padding: 10px;
  }
  .noAvailModal h2{
    font-size: 1.4rem;
    padding: 10px;
  }
  .noAvailModal .buttons{
    flex-direction: column;
  }
  .noAvailModal button{
    margin: 20px;
  }
}

/* phones landscape */
@media only screen and (max-width: 926px) and (max-height: 428px){
  .availabilityModal,
  .noAvailModal {
    top: 1%;
    right: 1%;
    bottom: 1%;
    left: 1%;
    justify-content: flex-start;
  }

  .availabilityModalInfo{
    max-width: 250px;
    padding-left: 15px;
  }

  .availabilityModalInfo h1{
    font-size: 1.7rem;
  }

  .availabilityModalInfo h2{
    font-size: 1.5rem;
  }

  .availabilityModalInfo p{
    padding-right: 8px;
  }

  .userNameInput{
    padding-left: 15px;
    padding-right: 10px;
  }

  .calendarContainer{
    margin-top: -345px;
  }


  /* no avail modal */
  .noAvailModal{
    padding: 0;
    justify-content: space-evenly;
  }
  
  .noAvailModal h1 {
      font-size: 1.8rem;
      padding: 10px;
    }
  
    .noAvailModal h2 {
      font-size: 1.4rem;
      padding: 10px;
    }
}

/* small phones */
@media only screen and (max-width: 699px) and (max-height: 400px){
  .availabilityModalInfo{
    max-width: 100%;
  }
  .availabilityModalInfo h1{
    padding-top: 5px;
  }
  
  .availabilityModal form{
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .availabilitySubmitBtn{
    position: absolute;
    top: 27px;
    right: 5%;
  }
  
  .userNameInput{
    width: 95.6%;
    padding: 15px;
  }
  .calendarContainer{
    margin-top: 0px;
  }
}

/* can't see availability calendar form */